@tailwind base;
@tailwind components;
@tailwind utilities;

::after, *{
 border-color:transparent;   
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: linear-gradient(180deg, #F0FFFF 0%, #E0FFFF 50%, #D3D3D3 100%);
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.import_details a{
  color:royalblue;
}
.import_details a:hover{
  color:blue;
  /* background-color: ; */
}

.test_row{
  font-style: italic;
  opacity: .77;
}

.dropdown_menu{
  z-index:900;
}